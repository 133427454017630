export const usePrimaryColor = () => {
  const primaryColor = useState<string | null | undefined>(
    "primaryColor",
    () => null
  );
  const { primaryColor: hireTruckersColor } = useAppConfig();
  const defaultColor = hireTruckersColor;
  const color = ref(defaultColor);

  const unbranded = useUnbranded();
  function decideColor(customColor?: string | null) {
    if (!customColor) return;
    if (!unbranded.value) {
      color.value = defaultColor;
    } else if (customColor) {
      color.value = customColor;
    } else {
      color.value = defaultColor;
    }
  }

  decideColor(primaryColor.value);

  watch(primaryColor, (value) => {
    decideColor(value);
  });

  function setPrimaryColor(color: string | null | undefined) {
    primaryColor.value = color;
  }

  return {
    primaryColor: color,
    setPrimaryColor,
  };
};
